import { Fragment, useEffect, useState } from 'react'
import axios from '@/apis/axios'
import EmailDialog from '@/Shared/Emails/EmailDialog'
import FormDialog from '@/Shared/Transaction/Forms/FormDialog'
import Helpers from '@/utils/helpers'
import Step from './Step'

export default function Steps({ showCompleted, section, transaction }) {
  const [emailDialogOpen, setEmailDialogOpen] = useState(false)
  const [emailTemplate, setEmailTemplate] = useState(null)
  const [formOpen, setFormOpen] = useState(false)
  const [form, setForm] = useState(null)
  let invalid_shortcodes = []

  useEffect(() => setEmailDialogOpen(Boolean(emailTemplate)), [emailTemplate])
  useEffect(() => setFormOpen(Boolean(form)), [form])

  const composeEmail = (step) => {
    axios.get(route('email-templates.show', step.actionable_id)).then((response) => {
      let subject = stripHTML(replaceVariables(response.data.subject, false))
      let content = replaceVariables(response.data.content)

      setEmailTemplate({
        step: step,
        template: {
          ...response.data,
          subject: subject,
          content: content,
          invalid_shortcodes: invalid_shortcodes,
        },
      })
    })
  }

  const openForm = (step) => {
    let activeForm = transaction.forms.find((form) => form.form_id == step.actionable_id)

    axios.get(route('transactions.forms.show', activeForm.id)).then((response) => {
      setForm(response.data)
    })
  }

  const filteredContacts = (partyRepresenting, role, onlyPrimary) => {
    return transaction.contacts.filter(
      (c) =>
        (!partyRepresenting || c.party_representing?.find((pr) => pr === partyRepresenting)) &&
        (!role || c.roles?.find((r) => r.name === role && (!onlyPrimary || r.primary))),
    )
  }

  const listValues = (value, index, total) => {
    return index == 0 ? value : (index + 1 < total ? ', ' : ' and ') + value
  }

  const stripHTML = (html) => {
    let doc = new DOMParser().parseFromString(html, 'text/html')
    return doc.body.textContent || ''
  }

  const replaceVariables = (content, allowsHtml = true) => {
    const variables = /<span[^>]*data-type=\"variable\"[^>]*>(.*?)<\/span>/gis

    return [...content.matchAll(variables)].reduce((result, match) => {
      let contacts = getContactsByShortcode(match[1]),
        replacement,
        shortcode = match[1]

      replacement =
        contacts?.length > 0 ? replaceShortcodeWithContactData(shortcode, contacts) : replaceShortcodeWithTransactionData(shortcode)

      if (!replacement && !invalid_shortcodes.find((code) => code == shortcode)) {
        replacement = replaceShortcodeWithInactiveRoleData(shortcode)

        if (!replacement) {
          invalid_shortcodes = invalid_shortcodes.concat(shortcode)
        }
      }

      return result.replace(
        match[0],
        replacement ||
          (allowsHtml
            ? `<span data-type="variable" data-label="${shortcode} Unavailable"></span>`
            : `{${shortcode} Unavailable}`.toUpperCase()),
      )
    }, content)
  }

  const getContactsByShortcode = (shortcode) => {
    let contacts,
      variations = '(fullname|firstname|contact-details|company|address|phone|email|license)'

    switch (true) {
      case new RegExp(`^buyer-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts(null, 'Buyer')
        break
      case new RegExp(`^buyer-accountant-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts('Buyer', 'Accountant')
        break
      case new RegExp(`^buyer-agent-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts('Buyer', 'Agent')
        break
      case new RegExp(`^buyer-agent-or-buyer-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts('Buyer', 'Agent')
        if (contacts.length == 0) {
          contacts = filteredContacts(null, 'Buyer')
        }
        break
      case new RegExp(`^buyer-estate-planner-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts('Buyer', 'Estate Planner')
        break
      case new RegExp(`^buyer-financial-advisor-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts('Buyer', 'Financial Advisor')
        break
      case new RegExp(`^buyer-lender-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts('Buyer', 'Lender')
        break
      case new RegExp(`^buyer-real-estate-attorney-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts('Buyer', 'Real Estate Attorney')
        break
      case new RegExp(`^closing-title-attorney-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts(null, 'Closing Title Attorney')
        break
      case new RegExp(`^condominium-association-manager-or-trustee-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts(null, 'Condominium Association Manager or Trustee')
        break
      case new RegExp(`^escrow-agent-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts(null, 'Escrow Agent')
        break
      case new RegExp(`^home-inspector-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts(null, 'Home Inspector')
        break
      case new RegExp(`^insurance-agent-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts(null, 'Insurance Agent')
        break
      case new RegExp(`^landlord-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts(null, 'Landlord')
        break
      case new RegExp(`^landlord-agent-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts('Landlord', 'Agent')
        break
      case new RegExp(`^landlord-agent-or-landlord-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts('Landlord', 'Agent')
        if (contacts.length == 0) {
          contacts = filteredContacts(null, 'Landlord')
        }
        break
      case new RegExp(`^landlord-real-estate-attorney-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts('Landlord', 'Real Estate Attorney')
        break
      case new RegExp(`^seller-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts(null, 'Seller')
        break
      case new RegExp(`^seller-accountant-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts('Seller', 'Accountant')
        break
      case new RegExp(`^seller-agent-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts('Seller', 'Agent')
        break
      case new RegExp(`^seller-agent-or-seller-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts('Seller', 'Agent')
        if (contacts.length == 0) {
          contacts = filteredContacts(null, 'Seller')
        }
        break
      case new RegExp(`^seller-estate-planner-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts('Seller', 'Estate Planner')
        break
      case new RegExp(`^seller-financial-advisor-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts('Seller', 'Financial Advisor')
        break
      case new RegExp(`^seller-lender-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts('Seller', 'Lender')
        break
      case new RegExp(`^seller-real-estate-attorney-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts('Seller', 'Real Estate Attorney')
        break
      case new RegExp(`^tenant-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts(null, 'Tenant')
        break
      case new RegExp(`^tenant-agent-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts('Tenant', 'Agent')
        break
      case new RegExp(`^tenant-agent-or-tenant-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts('Tenant', 'Agent')
        if (contacts.length == 0) {
          contacts = filteredContacts(null, 'Tenant')
        }
        break
      case new RegExp(`^tenant-real-estate-attorney-${variations}`, 'g').test(shortcode):
        contacts = filteredContacts('Tenant', 'Real Estate Attorney')
        break
    }
    return contacts
  }

  const replaceShortcodeWithContactData = (shortcode, contacts) => {
    if (shortcode.includes('email')) {
      return contacts[0].email || contacts[0].emails?.find((e) => e.primary)?.email || ''
    } else if (shortcode.includes('phone')) {
      return contacts[0].phone_formatted || contacts[0].contact?.phone_numbers.find((p) => p.primary)?.phone_formatted || ''
    } else if (shortcode.includes('company')) {
      return contacts[0].company || contacts[0].contact?.company || ''
    } else if (shortcode.includes('contact-details')) {
      let name = contacts.reduce(
          (output, contact, index) => output + listValues(contact.contact?.full_name || contact.legal_name, index, contacts.length),
          '',
        ),
        address = contacts[0].address || contacts[0].contact?.addresses?.find((a) => a.primary)?.address || '',
        phone =
          contacts[0].phone_formatted ||
          (contacts[0].contact?.phone_numbers?.length > 0 ? contacts[0].contact.phone_numbers.find((p) => p.primary)?.phone_formatted : ''),
        email = contacts[0].email || contacts[0].emails?.find((e) => e.primary)?.email || ''

      if (shortcode.includes('escrow') && contacts[0].contact?.associate) {
        name = ''
      }

      return (
        name +
        (name && (contacts[0].company || contacts[0].contact?.company) ? '<br>' : '') +
        (contacts[0].company || contacts[0].contact?.company || '') +
        (address ? `<br>${address}` : '') +
        (phone ? `<br>${phone}` : '') +
        (email ? `<br>${email}` : '')
      )
    } else if (shortcode.includes('address')) {
      return contacts[0].address || contacts[0].contact?.addresses?.find((a) => a.primary)?.address || ''
    } else if (shortcode.includes('firstname')) {
      return contacts.reduce(
        (output, contact, index) => output + listValues(contact.contact?.first_name || contact.first_name, index, contacts.length),
        '',
      )
    } else if (shortcode.includes('fullname')) {
      return contacts.reduce(
        (output, contact, index) => output + listValues(contact.contact?.full_name || contact.legal_name, index, contacts.length),
        '',
      )
    } else if (shortcode.includes('license')) {
      let licenses = contacts[0].contact?.associate?.licenses?.filter((license) =>
        transaction.type === 'Buyer'
          ? transaction.states.find((t) => t.state === license.state)
          : transaction.property_state === license.state,
      )

      return licenses?.reduce(
        (output, license, index) => output + listValues(`${license.state} - #${license.license}`, index, licenses.length),
        '',
      )
    }
  }

  const replaceShortcodeWithInactiveRoleData = (shortcode) => {
    let variations = '(fullname|firstname|contact-details|company|address|phone|email)'

    if (
      new RegExp(`^buyer-agent-${variations}`, 'g').test(shortcode) ||
      new RegExp(`^buyer-lender-${variations}`, 'g').test(shortcode) ||
      new RegExp(`^buyer-real-estate-attorney-${variations}`, 'g').test(shortcode) ||
      new RegExp(`^home-inspector-${variations}`, 'g').test(shortcode) ||
      new RegExp(`^insurance-agent-${variations}`, 'g').test(shortcode) ||
      new RegExp(`^seller-agent-${variations}`, 'g').test(shortcode) ||
      new RegExp(`^seller-lender-${variations}`, 'g').test(shortcode) ||
      new RegExp(`^seller-real-estate-attorney-${variations}`, 'g').test(shortcode)
    ) {
      const pattern = new RegExp(`\\b(${variations})\\b`, 'gi')
      const name = shortcode.replace(pattern, '').replace(/-$/, '').trim()
      const [firstWord, ...remainingWords] = name.split('-')

      const role = transaction.parties.some((party) => party.toLowerCase() === firstWord.toLowerCase())
        ? { party_representing: firstWord, name: remainingWords.join(' ') }
        : { name: name.replace('-', ' ') }

      if (
        transaction.inactive_roles.find((r) =>
          role.party_representing
            ? r.party_representing.toLowerCase() === role.party_representing && r.name.toLowerCase() === role.name
            : r.name.toLowerCase() === role.name,
        )
      ) {
        // prettier-ignore
        return (
          'There is no ' + name.replaceAll('-', ' ')
            .replace(/\b\w/g, (l) => l.toUpperCase()) // capitalize each word
            + ' for this transaction'
        )
      }
    }

    return ''
  }

  const replaceShortcodeWithTransactionData = (shortcode) => {
    switch (shortcode) {
      case 'commission-buyer':
        return (
          '$' +
          Helpers.formatDecimal(
            (transaction.type === 'Buyer' ? transaction.commission_blre : transaction.commission_cooperating) +
              transaction.commission_additional || 0,
            2,
          )
        )
      case 'commission-seller':
        return (
          '$' + Helpers.formatDecimal(transaction.type === 'Seller' ? transaction.commission_blre : transaction.commission_cooperating, 2)
        )
      case 'commission-blre':
        return (
          '$' +
          Helpers.formatDecimal(transaction.commission_blre + (transaction.type === 'Buyer' ? transaction.commission_additional : 0), 2)
        )
      case 'commission-cooperating':
        return (
          '$' +
          Helpers.formatDecimal(
            transaction.commission_cooperating + (transaction.type === 'Seller' ? transaction.commission_additional : 0),
            2,
          )
        )
      case 'home-inspection-date':
        return transaction.home_inspection_date_formatted
      case 'home-inspection-time':
        return transaction.home_inspection_time
      case 'closing-date':
        return transaction.closing_date_formatted
      case 'property-address':
        return transaction.property_address_inline
      case 'escrow-deposit1':
        return '$' + Helpers.formatDecimal(transaction.escrow_deposit1, 2)
      case 'escrow-deposit2':
        return '$' + Helpers.formatDecimal(transaction.escrow_deposit2, 2)
      case 'total-commission':
        return (
          '$' +
          Helpers.formatDecimal(transaction.commission_additional + transaction.commission_blre + transaction.commission_cooperating, 2)
        )
      case 'total-purchase-price':
        return '$' + Helpers.formatDecimal(transaction.total_purchase_price, 2)
      case 'walk-through-date':
        return transaction.walk_through_date_formatted
      case 'walk-through-time':
        return transaction.walk_through_time
      case 'deadline-for-sale-of-buyers-property-contingency-date':
        return transaction.deadline_for_sale_of_buyers_property_contingency_date_formatted
      case 'deadline-for-sellers-suitable-housing-contingency-date':
        return transaction.deadline_for_sellers_suitable_housing_contingency_date_formatted
      case 'deadline-for-receipt-review-of-condo-documents-date':
        return transaction.deadline_for_receipt_review_of_condo_documents_date_formatted
      case 'appraisal-date':
        return transaction.appraisal_date_formatted
      case 'appraisal-time':
        return transaction.appraisal_time
      case 'smoke-detector-certification-date':
        return transaction.smoke_detector_certification_date_formatted
      case 'smoke-detector-certification-time':
        return transaction.smoke_detector_certification_time
      case 'smoke-co-detector-inspection-fee':
        return transaction.smoke_co_detector_inspection_fee
      case 'final-water-reading-date':
        return transaction.final_water_reading_date_formatted
      case 'final-water-reading-time':
        return transaction.final_water_reading_time
      case 'offer-to-purchase-date':
        return transaction.offer_to_purchase_date_formatted
      case 'home-inspection-contingency-date':
        return transaction.home_inspection_contingency_date_formatted
      case 'purchase-and-sale-date':
        return transaction.purchase_and_sale_date_formatted
      case 'mortgage-commitment-date':
        return transaction.mortgage_commitment_date_formatted
      case 'fee-agreement-description':
        return transaction.fee_description
    }
  }

  return (
    <Fragment>
      {section.steps.map((step, index) => (
        <Step
          associates={transaction.contacts.filter((transactionContact) => transactionContact.contact_type === 'Associate')}
          completedShown={showCompleted}
          index={index}
          editable={!transaction.reviewing && !transaction.unlicensed_states && transaction.editable}
          last={
            index + 1 == section.steps.length || (!showCompleted && section.steps.filter((step) => !Boolean(step.completed_at)).length == 1)
          }
          step={step}
          transaction={transaction}
          key={step.id}
          onEmailOpening={(step) => composeEmail(step)}
          onFormOpening={(step) => openForm(step)}
        />
      ))}

      {emailTemplate && (
        <EmailDialog
          open={emailDialogOpen}
          step={emailTemplate.step}
          template={emailTemplate.template}
          transaction={transaction}
          onClosed={() => {
            setEmailDialogOpen(false)
            setTimeout(() => {
              setEmailTemplate(null)
            }, 100)
          }}
        />
      )}

      {form && (
        <FormDialog
          open={formOpen}
          record={form}
          onClosed={() => {
            setFormOpen(false)
            setTimeout(() => {
              setForm(null)
            }, 100)
          }}
        />
      )}
    </Fragment>
  )
}
