import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'
import MainLayout from '@/Shared/Layouts/Main'
import SearchBar from './SearchBar'
import classNames from 'classnames'
import Table from '@/Shared/Table/Index'
import TransactionsView from './TransactionsView'
import PendingTransactionsView from './PendingTransactionsView'
import { Fragment } from 'react'

const Team = ({ filters, viewTransactions }) => {
  const { team } = usePage().props

  if (!team) {
    return <></>
  }

  return (
    <Table>
      <Table.Header>
        <tr>
          <th className="h-12 cursor-pointer rounded-t bg-gray-200 px-4 font-medium text-gray-800" colSpan="2">
            <div className="flex items-center gap-3 leading-none">
              <div className="flex w-6 items-center justify-center">
                <i className="far fa-users text-gray-500"></i>
              </div>

              <span>Supported Associates ({team.length})</span>
            </div>
          </th>
        </tr>
      </Table.Header>
      <Table.Body>
        {team.map((associateContact) => (
          <TableRow
            text={
              <span>
                {associateContact.full_name} ({associateContact.transaction_count})
              </span>
            }
            active={filters.associates.find((contact) => contact.id == associateContact.id)}
            onClick={() => viewTransactions({ associates: [{ id: associateContact.id }] })}
            key={associateContact.id}
          />
        ))}
      </Table.Body>
    </Table>
  )
}

const TableRow = ({ active, icon, text, onClick }) => (
  <tr className={classNames(active ? 'bg-blue-100 text-blue-500' : 'text-gray-700 hover:bg-gray-100')}>
    <td className="cursor-pointer px-4 py-3 font-medium" onClick={() => onClick()}>
      <div className="flex items-center gap-3">
        {icon && (
          <div className="flex w-6 items-center justify-center">
            <i className={classNames(icon, active ? 'text-blue-500' : 'text-gray-400')}></i>
          </div>
        )}
        <div>{text}</div>
      </div>
    </td>

    <td className="w-16 cursor-pointer whitespace-nowrap py-3 pr-6 text-right font-medium sm:hidden" onClick={() => onClick()}>
      <div className="flex items-center justify-end">
        <i className="far fa-angle-right text-2xl leading-none text-gray-700"></i>
      </div>
    </td>
  </tr>
)

function TransactionsIndex() {
  const { team, pendingTransactions, supported_transaction_count, transaction_count, filters } = usePage().props
  const { user } = usePage().props.auth

  const viewTransactions = (newFilters) => {
    const { associates, supported, ...resetFilters } = filters
    const updatedFilters = { ...resetFilters, ...newFilters }
    const query = encodeURIComponent(JSON.stringify(updatedFilters))

    router.get(
      route(route().current(), {
        filters: query,
      }),
      {},
      {
        preserveState: true,
      },
    )
  }

  return (
    <div className="sm:flex sm:flex-col sm:overflow-hidden">
      <div className="sm:py-auto flex flex-wrap items-stretch justify-between border-b border-gray-200 bg-white px-4 py-4 md:min-h-[68px] md:px-6">
        <SearchBar />
      </div>

      <div className="sm:flex sm:flex-col sm:overflow-hidden">
        <div className="sm:flex sm:h-full sm:gap-3">
          {team?.length > 0 && (
            <div className="hidden space-y-3 bg-gray-100 py-3 pr-2 sm:ml-4 sm:block sm:w-[23rem] sm:overflow-y-auto">
              <Table>
                <Table.Body>
                  <TableRow
                    icon="far fa-list-check text-lg"
                    text={`My Transactions (${transaction_count})`}
                    active={
                      filters.associates.length == 1 &&
                      filters.associates.find((contact) => contact.id == user.contact_id) &&
                      !Boolean(filters.supported)
                    }
                    onClick={() => viewTransactions({ associates: [{ id: user.contact_id }] })}
                  />

                  {user.support_vp && (
                    <TableRow
                      text={`Supported (${supported_transaction_count})`}
                      active={Boolean(filters.supported)}
                      onClick={() => viewTransactions({ associates: [{ id: user.contact_id }], supported: true })}
                    />
                  )}
                </Table.Body>
              </Table>

              <Team filters={filters} viewTransactions={viewTransactions} />
            </div>
          )}

          <div
            className={classNames('w-full overflow-y-auto px-4 py-3 sm:flex sm:flex-col', {
              'sm:pl-0': team?.length > 0,
            })}
          >
            <div className="space-y-6">
              {pendingTransactions.length > 0 && (
                <Fragment>
                  <PendingTransactionsView />

                  <hr className="border-dashed border-gray-300" />
                </Fragment>
              )}

              <div className="space-y-3">
                {pendingTransactions.length > 0 && <h2 className="flex-1 text-xl font-medium leading-6 text-gray-900">My Transactions</h2>}

                <TransactionsView />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// Persistent layout
// Docs: https://inertiajs.com/pages#persistent-layouts
TransactionsIndex.layout = (page) => <MainLayout title="Transactions" children={page} />

export default TransactionsIndex
