import { Fragment } from 'react'
import { usePage } from '@inertiajs/react'
import classNames from 'classnames'
import { router } from '@inertiajs/react'
import HeaderColumn from '../../Shared/Table/HeaderColumn'

export default function PendingTransactionsView() {
  const { pendingTransactions } = usePage().props

  const rowClicked = (id) => {
    router.visit(route('transactions.show', id))
  }

  return (
    <div className="rounded-xl border-2 border-orange-300 bg-orange-100/50 px-4 pb-8 pt-6 sm:px-0">
      <div className="mx-auto w-full max-w-[96rem] space-y-3">
        <h2 className="flex-1 text-center text-xl font-medium leading-6 text-gray-900">
          <span className="font-bold text-orange-600">Awaiting Action:</span> Pending Transaction Invitations
        </h2>

        <div className="bg-white shadow sm:rounded">
          <div className="border-b border-gray-200 shadow">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <HeaderColumn classes="hidden px-0 md:table-cell">
                    <div className="flex-1 text-center">
                      <i className="fas fa-user-circle fa-2x text-gray-500"></i>
                    </div>
                  </HeaderColumn>
                  <HeaderColumn noBorder>Name</HeaderColumn>
                  <HeaderColumn noHover noBorder>
                    <span className="sr-only">Edit</span>
                  </HeaderColumn>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {pendingTransactions.map((item) => {
                  const { id, name, property_state, associates, propertyTypes, stage, states, status, completed_at, paused_at } = item

                  return (
                    <tr key={id}>
                      <td className="hidden whitespace-nowrap px-4 py-3 md:table-cell">
                        <div className="flex shrink-0 items-center justify-center">
                          {associates.map((associate, index) => (
                            <Fragment key={index}>
                              {associate.avatar && (
                                <div
                                  className={classNames(
                                    'overflow-hidden rounded-full ring-1 ring-white',
                                    index == 0 ? 'h-10 w-10' : 'h-9 w-9',
                                    index > 0 ? '-ml-2' : '',
                                  )}
                                  style={{ zIndex: associates.length - index }}
                                >
                                  <img className="object-contain" src={associate.avatar} alt="" aria-hidden="true" />
                                </div>
                              )}
                              {!associate.avatar && (
                                <div className="flex h-10 w-10 items-center justify-center rounded-full border border-gray-300 bg-gray-200 text-gray-700">
                                  <div className="text-xl font-medium tracking-wide">{associate.initials}</div>
                                </div>
                              )}
                            </Fragment>
                          ))}
                        </div>
                      </td>

                      <td className="cursor-pointer space-y-1 px-4 py-3 sm:pl-0 sm:pr-6" onClick={() => rowClicked(id)}>
                        <div className="text-lg font-medium text-blue-500 sm:text-base">{name}</div>

                        <div className="hidden flex-wrap items-center gap-1.5 lg:flex">
                          {completed_at ? (
                            <div className="inline-block whitespace-nowrap rounded bg-green-600 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-white">
                              <span>Completed</span>
                            </div>
                          ) : Boolean(paused_at) ? (
                            <div className="inline-block whitespace-nowrap rounded bg-red-600 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-white">
                              <span>Paused</span>
                            </div>
                          ) : status === 'Pending' ? (
                            <div className="inline-block whitespace-nowrap rounded bg-blue-500 px-2.5 py-1.5 text-sm leading-none text-white">
                              <span>Sale Pending</span>
                            </div>
                          ) : (
                            ['Archived', 'Lost'].indexOf(status) >= 0 && (
                              <div className="inline-block whitespace-nowrap rounded bg-red-600 px-2.5 py-1.5 text-sm leading-none text-white">
                                <span>{status}</span>
                              </div>
                            )
                          )}

                          {['On-Hold'].indexOf(status) >= 0 && (
                            <div className="inline-block whitespace-nowrap rounded bg-yellow-200/80 px-2.5 py-1.5 text-sm font-medium leading-none text-yellow-600">
                              <span>{status}</span>
                            </div>
                          )}

                          {['Compliance', 'Under Review'].indexOf(status) >= 0 && (
                            <div className="inline-block whitespace-nowrap rounded bg-orange-200/60 px-2.5 py-1.5 text-sm font-medium leading-none text-orange-600">
                              <span>{status}</span>
                            </div>
                          )}

                          {['Closed'].indexOf(status) >= 0 && (
                            <div className="inline-block whitespace-nowrap rounded bg-orange-500 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-white">
                              <span>Closed</span>
                            </div>
                          )}

                          {propertyTypes?.map((type, index) => (
                            <span
                              className="inline-block whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800"
                              key={index}
                            >
                              <span>{type.name}</span>
                            </span>
                          ))}

                          {property_state ? (
                            <span className="inline-block whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm uppercase leading-none text-gray-800">
                              <span>{property_state}</span>
                            </span>
                          ) : (
                            states?.map((state, index) => (
                              <span
                                className="inline-block whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800"
                                key={index}
                              >
                                <span>{state.state}</span>
                              </span>
                            ))
                          )}

                          {!completed_at && !Boolean(paused_at) && (
                            <div className="flex items-center justify-start gap-1.5">
                              <span className="inline-block rounded bg-blue-200/30 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-blue-700">
                                <span className="whitespace-pre-line leading-none sm:whitespace-nowrap">{stage.name}</span>
                              </span>
                              <span className="inline-block rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800">
                                {stage.elapsed}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="leading-normal xl:hidden">
                          {item.closing_at && <div>Closing Date: {item.closing_at}</div>}

                          {Boolean(paused_at) && (
                            <div className="mt-1 inline-block whitespace-nowrap rounded bg-red-600 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-white">
                              <span>Paused</span>
                            </div>
                          )}
                        </div>
                      </td>

                      <td className="cursor-pointer whitespace-nowrap py-3 pr-6 text-right font-medium" onClick={() => rowClicked(id)}>
                        <div className="flex items-center justify-end">
                          <i className="far fa-angle-right text-2xl leading-none text-gray-800"></i>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
